import type { ColumnDef, Row } from '@tanstack/react-table';
import SelectionTable from '../table/SelectionTable';
import { useListContext } from './ListContext';
import { useMemo, type FC } from 'react';
import { Skeleton } from '@chakra-ui/react';

interface ListResultsProps<TItem> {
  columns: ColumnDef<TItem, string>[];
  selection?: {
    selected: string[];
    onChange: (selected: string[]) => void;
    keyProperty: keyof TItem;
    maxSelected?: number;
  };
  expandedRow?: FC<{ row: Row<TItem> }>;
  rowHref?: (row: TItem) => string;
  globalExpand?: boolean;
}

export const ListResults = <TItem extends { id: string }>({
  selection,
  columns,
  expandedRow,
  rowHref,
  globalExpand,
}: ListResultsProps<TItem>) => {
  const { items, isLoading } = useListContext();

  const itemsOrLoadingItems = useMemo(
    () => (isLoading ? Array(10).fill({}) : items),
    [isLoading, items],
  );
  const columnsOrLoadingColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton height={5} />,
          }))
        : columns,
    [isLoading, columns],
  );
  return (
    <SelectionTable<TItem>
      data={itemsOrLoadingItems as TItem[]}
      columns={columnsOrLoadingColumns}
      selection={selection}
      expandedRow={expandedRow}
      rowHref={rowHref}
      globalExpand={globalExpand}
    />
  );
};
