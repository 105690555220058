import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Input,
  Menu,
  MenuButton,
  MenuList,
  VStack,
  Circle,
  Icon,
  Flex,
  Text,
  Box,
  Divider,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaFilter, FaBan } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { normalizeforSearch } from '@/utils/string';

export type FilterConfig = {
  [column: string]: {
    label: string;
    values: {
      label: string;
      value: string;
    }[];
  };
};
export type ListColumnFilterProps = {
  filterConfig: FilterConfig;
};

type ColumnFilterState = {
  [column: string]: {
    [keyValue: string]: { label: string; searchValue: string };
  };
};

const ListColumnFilter: React.FC<ListColumnFilterProps> = ({ filterConfig }) => {
  const { t } = useTranslation(['listFilters']);
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const { filters: queryFilters } = router.query;
  const parsedFilters = useMemo<Map<string, Set<string>>>(() => {
    return new Map<string, Set<string>>(
      String(queryFilters)
        .split(' ')
        .map((str) => {
          const [key, value] = str.split(':');
          if (key && value) {
            return [key, new Set(value.split(','))] as const;
          }
          return undefined;
        })
        .filter((e) => !!e),
    );
  }, [queryFilters]);

  const [selectedFilters, setSelectedFilters] = useState<Map<string, Set<string>>>(parsedFilters);

  const filters = useMemo(
    () =>
      Object.entries(filterConfig).reduce<ColumnFilterState>(
        (acc, [column, { label: columnLabel, values }]) => {
          acc[column] = {};
          values.forEach(({ value, label }) => {
            acc[column][value] = {
              label,
              searchValue: normalizeforSearch(`${column}|${columnLabel}|${value}|${label}`),
            };
          });
          return acc;
        },
        {},
      ),
    [filterConfig],
  );

  const displayedFilters = useMemo(() => {
    if (!searchString) return filters;
    const search = normalizeforSearch(searchString);
    const ret: ColumnFilterState = {};
    Object.entries(filters).forEach(([column, values]) => {
      const filteredValues = Object.entries(values).filter(([, { searchValue }]) =>
        searchValue.includes(search),
      );
      if (filteredValues.length) {
        ret[column] = filteredValues.reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {},
        );
      }
    });
    return ret;
  }, [filters, searchString]);

  const displayedElementsCount = useMemo(
    () => Object.entries(displayedFilters).length,
    [displayedFilters],
  );

  const selectedCount = useMemo(() => {
    return selectedFilters.values().reduce((acc, set) => acc + set.size, 0);
  }, [selectedFilters]);

  const handleApply = () => {
    const query = { ...router.query };
    query.filters = selectedFilters
      .entries()
      .filter(([, values]) => values.size)
      .map(([column, values]) => `${column}:${values.keys().toArray().join(',')}`)
      .filter((str) => str)
      .toArray()
      .join(' ');

    setIsOpen(false);
    router.replace({ query }, undefined, { scroll: false });
  };
  const handleReset = () => setSelectedFilters(new Map());
  const toggleFilter = (column: string, value: string) => {
    const selectedValues = selectedFilters.get(column) ?? new Set();
    if (selectedValues.has(value)) selectedValues.delete(value);
    else selectedValues.add(value);
    selectedFilters.set(column, selectedValues);
    setSelectedFilters(new Map(selectedFilters));
  };

  return (
    <Menu onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)} isOpen={isOpen}>
      <MenuButton as={Button} bg="gray.100" borderRadius="md" px={4} py={2}>
        <Flex align="center">
          <Icon as={FaFilter} fill={'gray.500'} boxSize={4} mr={2} />
          <Text fontWeight="medium">{t('listFilters:filter_by')}</Text>
          {selectedCount > 0 && (
            <Circle size="20px" bg="gray.500" color="white" fontSize="sm" ml={2}>
              {selectedCount}
            </Circle>
          )}
          <Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} boxSize={4} ml={2} />
        </Flex>
      </MenuButton>

      <MenuList p={4} minWidth="280px" borderRadius="md" boxShadow="lg" aria-hidden={!isOpen}>
        <Input
          placeholder={t('listFilters:search')}
          mb={4}
          size="sm"
          borderRadius="md"
          value={searchString}
          onChange={(event) => {
            setSearchString(event.target.value);
          }}
        />

        <VStack align="start" spacing={2} width="100%">
          {displayedElementsCount === 0 && (
            <Flex alignItems="center" direction={'row'}>
              <Icon as={FaBan} fill={'gray.500'} boxSize={4} mr={2} ml={1} />
              <Text color="gray.500">{t('listFilters:no_result')}</Text>
            </Flex>
          )}
          {displayedElementsCount > 0 &&
            Object.entries(displayedFilters).map(([column, value]) => (
              <Box key={column} width="100%">
                <Text fontSize="xs" fontWeight="bold" color="gray.500" mb={2}>
                  {filterConfig[column].label}
                </Text>
                <VStack align="start" spacing={2}>
                  {Object.keys(value).map((subKey) => (
                    <Checkbox
                      key={subKey}
                      isChecked={selectedFilters.get(column)?.has(subKey) ?? false}
                      onChange={() => toggleFilter(column, subKey)}
                    >
                      {displayedFilters[column]?.[subKey].label}
                    </Checkbox>
                  ))}
                </VStack>
                <Divider my={2} />
              </Box>
            ))}
        </VStack>

        <Box mt={4}>
          <Button
            size="sm"
            w="full"
            variant="outline"
            colorScheme="gray"
            onClick={handleReset}
            mb={2}
          >
            {t('listFilters:reset')}
          </Button>
          <Button size="sm" w="full" colorScheme="blackAlpha" onClick={handleApply}>
            {t('listFilters:apply', { selectedCount })}
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default ListColumnFilter;
