export function fromKebabToSnakeCase(value?: string) {
  return value?.replace(/-/g, '_') ?? '';
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function fromPascalToSnakeCase(value?: string) {
  return (
    value
      ?.replace(/([A-Z])/g, '_$1')
      .toLowerCase()
      .replace(/^_/, '') ?? ''
  );
}

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const normalizeforSearch = (value: string) =>
  value
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
