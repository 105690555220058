import type { PageDto, PageMetaDto, RequestParams } from '@/generated/typing';
import useToast from '@/hooks/useToast';
import { createGenericContext } from '@/utils/create-generic-context';
import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ListQueryParams } from './types';

type ListContextValue = {
  items?: { id: string }[];
  pageMeta?: PageMetaDto;
  pageLabel: string;
  queryLabel: string;
  q: string;
  currentPage: number;
  currentPageSize?: number;
  section?: string;
  setIsLoading: Function;
  isLoading: boolean;
  order?: string;
};
type ListContextProps = {
  queryLabel?: string;
  children: ReactNode;
  queryCacheName: QueryKey;
  query: (
    query: ListQueryParams,
    params?: RequestParams,
  ) => Promise<PageDto & { data?: { id: string }[] }>;
  section?: string;
  defaultLimit?: number;
};
export const [useListContext, ListContextProvider] = createGenericContext<ListContextValue>();
export const DEFAULT_QUERY_PAGE = 'page';
export const ListProvider = ({
  children,
  query,
  queryCacheName,
  defaultLimit,
  queryLabel = 'q',
}: ListContextProps) => {
  const router = useRouter();
  const toast = useToast();
  const { t } = useTranslation('common');

  const pageLabel = useMemo(
    () => (queryLabel === 'q' ? DEFAULT_QUERY_PAGE : `${queryLabel}-page`),
    [queryLabel],
  );
  const currentPage = useMemo(
    () => (router.query[pageLabel] ? Number(router.query[pageLabel]) : 1),
    [pageLabel, router.query],
  );

  const currentPageSize: number | undefined = useMemo(
    () => (router.query.limit ? Number(router.query.limit) : defaultLimit),
    [defaultLimit, router.query.limit],
  );

  const from = useMemo(
    () => (router.query.from ? new Date(router.query.from as string) : undefined),
    [router.query.from],
  );
  const to = useMemo(
    () => (router.query.to ? new Date(router.query.to as string) : undefined),
    [router.query.to],
  );
  const q = useMemo(
    () => (router.query[queryLabel] ? String(router.query[queryLabel]) : ''),
    [queryLabel, router.query],
  );
  const status = useMemo(
    () => (router.query.status ? router.query.status : undefined),
    [router.query.status],
  );
  const section = useMemo(
    () => (router.query.section ? String(router.query.section) : undefined),
    [router.query.section],
  );

  const order = useMemo(
    () => (router.query.order ? String(router.query.order) : undefined),
    [router.query.order],
  );

  const filters = useMemo(
    () => (router.query.filters ? String(router.query.filters) : undefined),
    [router.query.filters],
  );

  const [isLoading, setIsLoading] = useState(true);

  const { data } = useQuery(
    [
      ...(Array.isArray(queryCacheName) ? queryCacheName : [queryCacheName]),
      { page: currentPage, limit: currentPageSize, from, to, q, status, filters, order },
    ],
    async () => {
      const response = await query({
        page: currentPage,
        limit: currentPageSize,
        from: from?.toISOString(),
        to: to?.toISOString(),
        q: q?.length ? q : undefined,
        status: status ? [...(Array.isArray(status) ? status : [status])] : undefined,
        filters,
        section,
        order,
      });
      setIsLoading(false);
      return response;
    },
    {
      keepPreviousData: true,
      onError: () => {
        toast({
          title: t('fetching_data_error'),
          status: 'error',
        });
      },
    },
  );

  return (
    <ListContextProvider
      value={{
        items: data?.data,
        pageMeta: data?.meta,
        pageLabel,
        queryLabel,
        q,
        currentPage,
        currentPageSize,
        section,
        isLoading,
        setIsLoading,
        order,
      }}
    >
      {children}
    </ListContextProvider>
  );
};
